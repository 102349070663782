export const FirebaseConfig = {
	"projectId": "amagaadisept22",
	"appId": "1:44346243055:web:804c89166eccf27ecd681a",
	"databaseURL": "https://amagaadisept22-default-rtdb.firebaseio.com",
	"storageBucket": "amagaadisept22.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAz7nXEOpqJNZRLaUt0JhArKebyANGNmo4",
	"authDomain": "amagaadisept22.firebaseapp.com",
	"messagingSenderId": "44346243055",
	"measurementId": "G-VQQLSNNRVL"
};